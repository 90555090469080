// Toggle Mobile Menu
function LS_togglemenu() {
	$('#LS_header_mobile').toggleClass('open');
}

// Close mobile menu when anchor link for contact form is clicked
document.addEventListener('DOMContentLoaded', function() {
    var contactLink = document.getElementById('contact_link');
    var LSHeaderMobile = document.getElementById('LS_header_mobile');

    contactLink.addEventListener('click', function() {
        // Remove the class 'open' from the LS_header_mobile div
        LSHeaderMobile.classList.remove('open');
    });
});

// Header image carousel


document.addEventListener('DOMContentLoaded', function() {
	var images = [
		'https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_header_home_1.jpg',
		'https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_header_home_2.jpg',
		'https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_header_home_3.jpg'
	];
	var currentIndex = 0;
	var headerImages = document.querySelector('.LS_header_images');

	function changeImage() {
		var nextIndex = (currentIndex + 1) % images.length;
		var nextImageUrl = 'url(' + images[nextIndex] + ')';

		headerImages.style.opacity = 0; // Fade out the current image

		setTimeout(function() {
			headerImages.style.backgroundImage = nextImageUrl; // Change to the next image
			headerImages.style.opacity = 1; // Fade in the next image
			currentIndex = nextIndex;
		}, 330); // Transition duration
	}

	changeImage(); // Initial call to start the carousel

	setInterval(changeImage, 5000); // Repeat the carousel every 5 seconds
});


// Header Menu Curve

$(document).ready(function() {
    // Check if the URL contains '/collections/'
    if (window.location.href.indexOf('/collections/') > -1) {
        // Add the class 'LS_header_container_curve' to the div with class 'LS_header_container'
        $('.LS_header_container').addClass('LS_header_container_curve');
        $('.LS_header_mobile').addClass('LS_header_mobile_curve');
    }
   if (window.location.href.indexOf('/products/') > -1) {
        // Add the class 'LS_header_container_curve' to the div with class 'LS_header_container'
        $('.LS_header_container').addClass('LS_header_container_curve');
        $('.LS_header_mobile').addClass('LS_header_mobile_curve');
    }
	if (window.location.href.indexOf('/cart') > -1) {
        // Add the class 'LS_header_container_curve' to the div with class 'LS_header_container'
        $('.LS_header_container').addClass('LS_header_container_curve');
        $('.LS_header_mobile').addClass('LS_header_mobile_curve');
    }
    if (window.location.href.indexOf('/pages/contact-us') > -1) {
        // Add the class 'LS_header_container_curve' to the div with class 'LS_header_container'
        $('.LS_header_container').addClass('LS_header_container_curve');
        $('.LS_header_mobile').addClass('LS_header_mobile_curve');
    }

   // Url for Collections 'All' list highlight
    if (window.location.href.indexOf('/collections/all') > -1) {
        // Add the class 'LS_header_container_curve' to the div with class 'LS_header_container'
        $('.LS_collections_all').addClass('LS_collections_active');
    }
});

